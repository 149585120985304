<template>
  <div class="serverBox">
    <div class="list">
      <el-row :gutter="20" v-if="dataList.length">
        <el-col 
          :span="rowSpan"
          v-for="(item, index) in dataList" 
          :key="index"
          @click.native="goDetail(item)">
          <serverItem 
            :item="item" 
            @closePopover="closePopover" 
            @edit="editItem"
            @update="getList"
            @consult="consultEvent"
          />
        </el-col>
      </el-row>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="100"
      />
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="changePageSizeHandler"
        @current-change="changePageHandler"
        :current-page="search.page"
        :page-sizes="[20, 30, 40, 50, 100]"
        :total="total"
        background
        layout="prev, pager, next, sizes, total, jumper"
      />
    </div>
    <addServer ref="addServer" :companyId="params.companyId" @updateList="getList" />
    <serverDrawer 
      ref="serverDrawer" 
      :companyId="params.companyId"
      @updateList="getList" 
      @edit="editItem"
      @consult="consultEvent"
    />
    <inside
      :isInside="isInside"
      @onClose="isInside = false"
      type="shopServer"
      :shopServerId="shopServerId"
      :companyId="params.companyId"
      :messageType="12"
    />
    <externalShare ref="externalShare" />
  </div>
</template>

<script>
import { getForwarderServerList } from "@/api/shopServer.js";

import addServer from '../dialog/addServer.vue';
import serverItem from '../itemComponents/server.vue';
import serverDrawer from "../detailDrawer/server.vue";
import inside from "@/components/fileInside/inside.vue";
import externalShare from "../share/externalShare.vue";

export default {
  components: {
    addServer,
    serverItem,
    serverDrawer,
    inside,
    externalShare
  },
  props: {
    params: {
      type: Object,
      default: null
    },
    rowSpan: {
      type: Number | String,
      default: 12
    }
  },
  data() {
    return {
      shopServerId: "",
      isInside: false,
      loading: false,
      search: {
        page: 1,
        size: 20
      },
      dataList: [],
      total: 0
    }
  },
  methods: {
    getList(clear) {
      if(clear) {
        this.search.page = 1;
        this.dataList = [];
      }
      this.loading = true;
      getForwarderServerList({
        ...this.params,
        ...this.search,
      }).then((data) => {
        this.dataList = data.list.map(item => ({
          ...item,
          check: false,
          shippingCompanyName: item.systemShippingCompanyDTO?.companyEnName,
          shippingCompanyId: item.systemShippingCompanyDTO?.shippingCompanyId,
          shippingCompanyLogoUrl: item.systemShippingCompanyDTO?.logoUrl,
        }));
        this.total = data.total;
        this.$refs.serverDrawer.handleClose();
      }).finally(() => this.loading = false);
    },
    offSiteShareEvent() {
      this.$refs.externalShare.open(null, this.params.companyId, 2);
    },
    consultEvent(id) {
      this.shopServerId = id;
      this.isInside = true;
    },
    goDetail(row) {
      this.$refs.serverDrawer.open(row);
    },
    addItem() {
      this.$refs.addServer.open();
    },
    editItem(row) {
      this.$refs.addServer.open(row);
    },
    closePopover(id) {
      this.dataList.forEach((item, index) => {
        if(item.serviceId != id) {
          item.check = false;
        }
      });
    },
    changePageHandler(page) {
      this.search.page = page;
      this.getList();
    },
    changePageSizeHandler(pageSize) {
      this.search.size = pageSize;
      this.getList();
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  height: calc(100vh - 210px);
  overflow: overlay;
  overflow-x: hidden;
  margin-top: 24px;
  /deep/ .el-empty {
    margin-top: 100px;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
}
</style>