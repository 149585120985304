<template>
  <el-dialog 
    :visible.sync="show"
    :title="$t('Operation records')"
    :before-close="close"
    width="700px"
    append-to-body
    v-move-outside>
    <el-table
      :data="operationList"
      style="width: 100%"
      :show-header="false">
      <el-table-column
        prop="createUser"
        width="120">
        <template slot-scope="{row}">
          <div class="customerName" v-if="row.createUser">
            <member :info="row.createUser" :size="28"></member>
            <span>{{ row.createUser.nickName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        width="500px">
        <template slot-scope="{row}">
          <div class="operationContent">
            {{ row.type == 0 ? $t('Create') : row.type == 1 ? $t('home.Edit') : $t('home.Delete') }}
            <div class="operationDetail">
              <p class="line1" v-showTootip>{{ row.fieldName }} {{ row.fieldValueOld || row.fieldValueNew }}</p>
              <span v-if="row.type == 1">{{ $t('do') }}</span>
              <p class="line1" v-if="row.type == 1" v-showTootip>{{ row.fieldName }} {{ row.fieldValueNew }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('Files.CreateTime')"
        width="180"
        fixed="right">
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getForwarderServerRecords } from "@/api/shopServer.js";
export default {
  data() {
    return {
      show: false,
      operationList: []
    }
  },
  methods: {
    open(id) {
      this.show = true;
      getForwarderServerRecords(id).then((data) => {
        this.operationList = data;
      })
    },
    close() {
      this.show = false;
    }
  }
}
</script>

<style>

</style>