<template>
  <div>
    <el-dialog
      :title="!row ? $t('home.CIAdd') : $t('home.Edit')"
      :visible.sync="show"
      width="668px"
      append-to-body
      :before-close="close"
      v-move-outside
    >
      <layout>
        <template #config>
          <el-form
            ref="ruleForm"
            v-if="show"
            label-width="0"
            size="small"
            :model="form"
            :rules="rules"
            class="drawerForm"
          >
            <el-form-item prop="fileName">
              <div class="title">{{ $t("Files.File Name") }}</div>
              <div class="avatarForm">
                <el-input
                  type="text"
                  :placeholder="$t('order.Please input content')"
                  v-model="form.fileName"
                  maxlength="50"
                  show-word-limit
                  @input="generateChange"
                />
              </div>
            </el-form-item>
            <el-form-item prop="description">
              <div class="title">{{ $t("Description") }}</div>
              <div class="avatarForm">
                <el-input
                  type="textarea"
                  :placeholder="$t('order.Please input content')"
                  v-model="form.description"
                  maxlength="50"
                  show-word-limit
                  @input="generateChange"
                />
              </div>
            </el-form-item>
            <el-form-item>
              <div class="title">{{ $t("order.Event tag") }}({{`${form.tags.length}/3`}})</div>
              <div class="avatarForm">
                <div class="tagList">
                  <div 
                    class="item" 
                    v-for="(item, index) in form.tags" 
                    :key="index"
                    :style="{
                      background: item.color
                    }">
                    {{ item.content }}
                    <i class="el-icon-error" @click="deleteTag(index)"></i>
                  </div>
                  <div class="addTag" v-if="form.tags.length < 3" @click="addTag">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="attachmentId.id" :rules="rules.attachmentId">
              <div class="title">{{ $t("Attachment") }}</div>
              <el-upload
                v-loading="uploadLoding"
                :auto-upload="false"
                action="#"
                drag
                :show-file-list="false"
                :on-change="handleChange"
                :class="!form.attachmentId.url ? 'ec-upload' : 'ec-uploader'"
                :accept="fileTypeFileConfig.supportFileTypes"
              >
                <template v-if="!form.attachmentId.url">
                  <i class="iconfont icon-shangchuan"></i>
                  <div class="el-upload__text">
                    {{ $t("event.Drag & Drop files here OR Browse Files") }}
                  </div>
                </template>
                <template v-else>
                  <div class="ec-upload__left">
                    <i
                      class="iconfont_file"
                      :class="$isFileTypeIcon(fileName.split('.')[1])"
                    ></i>
                    <div class="name">
                      <p>{{ fileName }}</p>
                    </div>
                  </div>
                  <i class="iconfont icon-shanchu" @click.stop="handleRemove"></i>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>
        </template>
        <template #component>
          <fileItem :item="previewItem" />
          <fileBlocks
            v-if="form.attachmentId.url"
            type="message"
            background="#F7F9FC"
            :embedUrl="form.attachmentId.url"
            :embedId="form.attachmentId.id"
          />
        </template>
      </layout>
      <span slot="footer" class="dialog-footer">
        <div class="left">
          <el-button 
            v-if="row"
            class="cancel_button" 
            size="small" 
            icon="el-icon-time"
            @click="seeRecords">
            {{ $t('Operation records') }}
          </el-button>
        </div>
        <div class="right">
          <el-button 
            class="cancel_button" 
            size="small" 
            @click="close">
            {{ $t('home.Cancel') }}
          </el-button>
          <el-button 
            type="primary" 
            size="small" 
            :loading="btnLoading"
            @click="confirm">
            {{ $t('home.Confirm') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog 
      :visible.sync="tagDialogShow"
      :title="$t('home.CIAdd')+$t('order.Event tag')"
      :before-close="handleTagClose"
      width="358px"
      append-to-body>
      <el-input 
        v-model="addForm.content" 
        :maxlength="6" 
        show-word-limit 
        size="small"
        :placeholder="$t('order.Tag type name')" 
      />
      <div class="colorBox">
        <div class="label">{{ $t('color') }}</div>
        <div class="colors">
          <div 
            class="item" 
            v-for="(item, index) in colorOptions" 
            :key="index" 
            :class="item.check ? 'active' : ''"
            :style="{
              background: item.value
            }"
            @click="colorChange(item)"
          ></div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div></div>
        <div>
          <el-button 
            class="cancel_button" 
            size="small" 
            @click="handleTagClose"
          >{{$t("home.Cancel")}}</el-button>
          <el-button
            type="primary"
            size="small"
            @click="submitTagAdd"
          >{{ $t("home.ConfirmAgree") }}</el-button>
        </div>
      </div>
    </el-dialog>
    <records ref="records" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import layout from '../../customFormConfig/config/components/layout.vue';
import fileItem from '../itemComponents/file.vue';
import records from "./records.vue";

import { createFile, editFile } from "@/api/shopServer.js";
import { uploadFileUrl } from "@/api/files.js";

export default {
  components: {
    layout,
    fileItem,
    records
  },
  props: {
    companyId: {
      type: String | Number,
      default: ""
    }
  },
  data() {
    return {
      show: false,
      row: null,
      btnLoading: false,
      uploadLoding: false,
      tagDialogShow: false,
      form: {
        fileName: "",
        description: "",
        tags: [],
        attachmentId: {
          id: "",
          url: ""
        }
      },
      previewItem: {
        fileName: this.$t('event.Title'),
        description: this.$t('Description'),
        tags: [],
        attachmentName: "file.jpg",
        attachmentSize: "0.00MB",
      },
      rules: {
        fileName: [{
          required: true, message: this.$tc('Please input', this.$t("Files.File Name")), trigger: 'blur'
        }],
        description: [{
          required: true, message: this.$tc('Please input', this.$t("Description")), trigger: 'blur'
        }],
        attachmentId: [{
          required: true, message: this.$tc('Please select event', this.$t("Attachment")), trigger: 'change'
        }]
      },
      fileSize: "0.00MB",
      addForm: {
        content: "",
        color: "#225E7A"
      },
      colorOptions: [{
        value: "#225E7A",
        check: true
      }, {
        value: "#F3AA62",
        check: false
      }, {
        value: "#EF6464",
        check: false
      }, {
        value: "#478B97",
        check: false
      }, {
        value: "#B8621B",
        check: false
      }, {
        value: "#3075C6",
        check: false
      }, {
        value: "#4B508C",
        check: false
      }, {
        value: "#076F49",
        check: false
      }],
      fileTypeFileConfig: null
    }
  },
  computed: {
    ...mapGetters(['fileType']),
    fileName() {
      let text = this.form.attachmentId.url.split("/")?.pop();
      let index_ = text.lastIndexOf("_");
      let indexdian = text.lastIndexOf(".");
      return text.substring(0, index_) + text.substring(indexdian, text.length);
    }
  },
  mounted() {
    this.fileTypeFileConfig = this.fileType.find(item => item.configCode == 'goods_file');
  },
  methods: {
    open(row = null) {
      this.row = row;
      this.show = true;
      if(row) {
        this.fileSize = row.attachmentSize;
        this.form = {
          companyId: this.companyId,
          goodsFileId: row.goodsFileId,
          fileName: row.fileName,
          description: row.description,
          tags: row.tags,
          attachmentId: {
            id: row.attachmentId,
            url: row.attachmentUrl
          }
        }
        setTimeout(() => {
          this.generateChange();
        }, 300);
      }
    },
    generateChange() {
      this.previewItem = {
        fileName: this.form.fileName || this.$t('event.Title'),
        description: this.form.description || this.$t('Description'),
        tags: this.form.tags,
        attachmentName: this.fileName || 'file.jpg',
        attachmentSize: this.fileSize,
      }
    },
    addTag() {
      this.tagDialogShow = true;
    },
    handleTagClose() {
      this.tagDialogShow = false;
      this.addForm = {
        content: "",
        color: "#225E7A"
      }
      this.colorOptions.forEach((item, index) => {
        if(!index) item.check = true;
        else item.check = false;
      });
    },
    colorChange(row) {
      this.colorOptions.forEach(item => {
        item.check = false;
      });
      row.check = !row.check;
    },
    submitTagAdd() {
      if(!this.addForm.content) {
        this.$message.warning(this.$t('order.Please input title'))
        return;
      }
      this.form.tags.push({
        content: this.addForm.content,
        color: this.colorOptions.find(item => item.check).value
      });
      this.handleTagClose();
      this.generateChange();
    },
    seeRecords() {
      this.$refs.records.open(this.row.goodsFileId);
    },
    deleteTag(index) {
      this.form.tags.splice(index, 1);
      this.generateChange();
    },
    beforeUpload(file, typeConfig) {
      let type = '.'+file.name.split(".").pop();
      if(!typeConfig.supportFileTypes.split(",").includes(type.toLowerCase())) {
        this.$message.error(this.$t("File format not supported"));
        return;
      }
      const isLt10M = file.size / 1024 / 1024 < Number(typeConfig.maxUploadSize);
      if (!isLt10M) {
        this.$message.error(this.$tc("order.File is too large to upload", Number(typeConfig.maxUploadSize)));
      }
      return isLt10M;
    },
    handleRemove() {
      this.form.attachmentId.id = "";
      this.form.attachmentId.url = "";
    },
    handleChange(file) {
      //文件数量改变
      if (!this.beforeUpload(file, this.fileTypeFileConfig)) return;
      this.confirmUpload(file);
    },
    confirmUpload(file) {
      this.uploadLoding = true;
      var data_up = new FormData();
      data_up.append("files", file.raw);
      data_up.append("configCode", "goods_file");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        this.form.attachmentId.url = response[0].savePath;
        this.form.attachmentId.id = response[0].fileId;
        this.fileSize = ((file.size / 1024 / 1024).toFixed(2)) + 'MB';
        this.generateChange();
      }).finally((error) => {
        this.uploadLoding = false;
      });
    },
    close() {
      this.show = false;
      this.row = null;
      this.form = {
        fileName: "",
        description: "",
        tags: [],
        attachmentId: {
          id: "",
          url: ""
        }
      }
      this.previewItem = {
        fileName: this.$t('event.Title'),
        description: this.$t('Description'),
        tags: [],
        attachmentName: "file.jpg",
        attachmentSize: "0.00MB",
      }
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if(!valid) return;
        let obj = JSON.parse(JSON.stringify(this.form));
        this.btnLoading = true;
        if(this.row) {
          editFile({
            ...obj,
            tags: JSON.stringify(obj.tags),
            attachmentId: obj.attachmentId.id,
            companyId: this.companyId
          }).then(() => {
            this.$message.success(this.$t('home.editSuccess'));
            this.close();
            this.$emit("updateList", false);
          }).finally(() => {
            this.btnLoading = false;
          });
          return;
        }
        createFile({
          ...obj,
          tags: JSON.stringify(obj.tags),
          attachmentId: obj.attachmentId.id,
          companyId: this.companyId
        }).then(() => {
          this.$message.success(this.$t('home.createSuccess'));
          this.close();
          this.$emit("updateList", true);
        }).finally(() => {
          this.btnLoading = false;
        });
      })
    }
  }
}
</script>

<style lang="less" scoped>
.drawerForm {
  padding: 0;
}
/deep/ .componentBox {
  .right {
    background: transparent;
    padding: 0;
    min-width: 0;
    .components {
      padding-top: 10px;
      .drawerForm {
        padding: 0;
        height: 100%;
        .caseItemBox {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .item {
    border-radius: 4px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #ffffff;
    line-height: 22px;
    i {
      cursor: pointer;
    }
  }
  .addTag {
    height: 32px;
    width: 88px;
    border: 1px dashed #0E559C;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      color: #0E559C;
      font-weight: 500;
    }
  }
}
.colorBox {
  display: flex;
  align-items: center;
  margin-top: 18px;
  .label {
    color: #637381;
    font-size: 12px;
    line-height: 22px;
    flex: 1;
  }
  .colors {
    display: flex;
    align-items: center;
    gap: 13px;
    .item {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        position: relative;
        &::before {
          content: "\e6da";
          font-family: "element-icons";
          font-size: 16px;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          width: 14px;
          height: 14px;
          background: #48AC42;
          color: #ffffff;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  .left {
    /deep/ .el-button {
      padding: 9px 0;
    }
  }
}
</style>