<template>
  <div>
    <el-dialog
      :title="!row ? $t('home.CIAdd') : $t('home.Edit')"
      :visible.sync="show"
      width="668px"
      append-to-body
      :before-close="close"
      v-move-outside
    >
      <layout>
        <template #config>
          <el-form
            ref="ruleForm"
            v-if="show"
            label-width="0"
            size="small"
            :model="form"
            :rules="rules"
            class="drawerForm"
          >
            <el-form-item prop="shippingCompanyId">
              <div class="title">{{ $t("noticeServer.Carrier") }}</div>
              <div class="avatarForm">
                <el-select 
                  v-model="form.shippingCompanyId" 
                  filterable 
                  :placeholder="$tc('Please select event', $t('noticeServer.Carrier'))"
                  @change="generateChange">
                  <el-option
                    v-for="item in carrierOptions"
                    :key="item.shippingCompanyId"
                    :label="item.companyEnName"
                    :value="item.shippingCompanyId">
                    <div class="customer_name">
                      <el-image :src="item.logoUrl" fit="contain" />
                      <div class="companyName">
                        <div class="name">{{ item.companyEnName }}</div>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item prop="departure">
              <div class="title">{{ $t("noticeServer.Place of departure") }}</div>
              <div class="avatarForm">
                <el-select 
                  v-model="form.departure" 
                  filterable 
                  :placeholder="$tc('Please select event', $t('noticeServer.Place of departure'))"
                  @change="generateChange">
                  <el-option
                    v-for="item in harborOptions"
                    :key="item.harborId"
                    :label="item.harborName"
                    :value="item.harborId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item prop="destination">
              <div class="title">{{ $t("noticeServer.Destination") }}</div>
              <div class="avatarForm">
                <el-select 
                  v-model="form.destination" 
                  filterable 
                  :placeholder="$tc('Please select event', $t('noticeServer.Destination'))"
                  @change="generateChange">
                  <el-option
                    v-for="item in harborOptions"
                    :key="item.harborId"
                    :label="item.harborName"
                    :value="item.harborId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="title">{{ $t("noticeServer.Transfer") }}</div>
              <div class="avatarForm">
                <el-select 
                  v-model="form.transfer" 
                  filterable 
                  clearable
                  :placeholder="$tc('Please select event', $t('noticeServer.Transfer'))"
                  @change="generateChange">
                  <el-option
                    v-for="item in harborOptions"
                    :key="item.harborId"
                    :label="item.harborName"
                    :value="item.harborId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item prop="time">
              <div class="title">{{ $t("Lifespan") }}</div>
              <div class="avatarForm">
                <el-date-picker
                  v-model="form.time"
                  size="small"
                  type="daterange"
                  :start-placeholder="$t('el.datepicker.startDate')"
                  :end-placeholder="$t('el.datepicker.endDate')"
                  @change="generateChange"
                />
              </div>
            </el-form-item>
          </el-form>
        </template>
        <template #component>
          <serverItem :item="previewItem" />
        </template>
      </layout>
      <span slot="footer" class="dialog-footer">
        <div class="left">
          <el-button 
            v-if="row"
            class="cancel_button" 
            size="small" 
            icon="el-icon-time"
            @click="seeRecords">
            {{ $t('Operation records') }}
          </el-button>
        </div>
        <div class="right">
          <el-button 
            class="cancel_button" 
            size="small" 
            @click="close">
            {{ $t('home.Cancel') }}
          </el-button>
          <el-button 
            type="primary" 
            size="small" 
            :loading="btnLoading"
            @click="confirm">
            {{ $t('home.Confirm') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
    <records ref="records" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import layout from '../../customFormConfig/config/components/layout.vue';
import serverItem from '../itemComponents/server.vue';
import records from "./records.vue";

import { getCarrierList, getHarborList, createForwarderServer, editForwarderServer } from "@/api/shopServer.js";

export default {
  components: {
    layout,
    serverItem,
    records
  },
  props: {
    companyId: {
      type: String | Number,
      default: ""
    }
  },
  data() {
    return {
      show: false,
      btnLoading: false,
      row: null,
      form: {
        shippingCompanyId: "",
        departure: "",
        destination: "",
        transfer: "",
        time: []
      },
      previewItem: {
        shippingCompanyName: "ESL",
        shippingCompanyLogoUrl: "https://echola-pre.oss-cn-hangzhou.aliyuncs.com/file/carrier/ESL4010x.png",
        departureName: "南沙港",
        departureCountry: "中国",
        transferName: "",
        destinationName: "江门港",
        destinationCountry: "中国",
        startTime: "2023-11-02 15:49:42",
        endTime: "2023-11-15 15:49:42"
      },
      rules: {
        shippingCompanyId: [{
          required: true, message: this.$tc('Please select event', this.$t("noticeServer.Carrier")), trigger: 'change'
        }],
        departure: [{
          required: true, message: this.$tc('Please select event', this.$t("noticeServer.Place of departure")), trigger: 'change'
        }],
        destination: [{
          required: true, message: this.$tc('Please select event', this.$t("noticeServer.Destination")), trigger: 'change'
        }],
        time: [{
          required: true, message: this.$tc('Please select event', this.$t("Lifespan")), trigger: 'change'
        }]
      },
      carrierOptions: [],
      harborOptions: []
    }
  },
  methods: {
    open(row = null) {
      this.row = row;
      this.show = true;
      getCarrierList({
        page: 1,
        size: 10000
      }).then((data) => {
        this.carrierOptions = data.list;
        getHarborList({
          page: 1,
          size: 10000
        }).then((data) => {
          this.harborOptions = data.list;
          if(row) {
            this.form = {
              companyId: this.companyId,
              serviceId: row.serviceId,
              shippingCompanyId: row.shippingCompanyId,
              departure: row.departure,
              destination: row.destination,
              transfer: row.transfer,
              time: [row.startTime, row.endTime]
            }
            this.$nextTick(() => {
              let startTime = this.form.time.length ? this.$dayjs(this.form.time[0]).startOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
              let endTime = this.form.time.length ? this.$dayjs(this.form.time[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
              this.previewItem = {
                shippingCompanyName: this.carrierOptions.find(item => item.shippingCompanyId == this.form.shippingCompanyId)?.companyEnName || 'ESL',
                shippingCompanyLogoUrl: this.carrierOptions.find(item => item.shippingCompanyId == this.form.shippingCompanyId)?.logoUrl || 'https://echola-pre.oss-cn-hangzhou.aliyuncs.com/file/carrier/ESL4010x.png',
                departureName: this.harborOptions.find(item => item.harborId == this.form.departure)?.harborName || '南沙港',
                departureCountry: this.harborOptions.find(item => item.harborId == this.form.departure)?.countryName || '中国',
                destinationName: this.harborOptions.find(item => item.harborId == this.form.destination)?.harborName || '江门港',
                destinationCountry: this.harborOptions.find(item => item.harborId == this.form.destination)?.countryName || '中国',
                transferName: this.harborOptions.find(item => item.harborId == this.form.transfer)?.harborName || "",
                startTime: startTime || '2023-11-02 15:49:42',
                endTime: endTime || '2023-11-15 15:49:42'
              }
            });
          }
        })
      })
    },
    generateChange() {
      let startTime = this.form.time.length ? this.$dayjs(this.form.time[0]).startOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
      let endTime = this.form.time.length ? this.$dayjs(this.form.time[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
      this.previewItem = {
        shippingCompanyName: this.carrierOptions.find(item => item.shippingCompanyId == this.form.shippingCompanyId)?.companyEnName || 'ESL',
        shippingCompanyLogoUrl: this.carrierOptions.find(item => item.shippingCompanyId == this.form.shippingCompanyId)?.logoUrl || 'https://echola-pre.oss-cn-hangzhou.aliyuncs.com/file/carrier/ESL4010x.png',
        departureName: this.harborOptions.find(item => item.harborId == this.form.departure)?.harborName || '南沙港',
        departureCountry: this.harborOptions.find(item => item.harborId == this.form.departure)?.countryName || '中国',
        destinationName: this.harborOptions.find(item => item.harborId == this.form.destination)?.harborName || '江门港',
        destinationCountry: this.harborOptions.find(item => item.harborId == this.form.destination)?.countryName || '中国',
        transferName: this.harborOptions.find(item => item.harborId == this.form.transfer)?.harborName || "",
        startTime: startTime || '2023-11-02 15:49:42',
        endTime: endTime || '2023-11-15 15:49:42'
      }
    },
    close() {
      this.show = false;
      this.row = null;
      this.form = {
        shippingCompanyId: "",
        departure: "",
        destination: "",
        transfer: "",
        time: []
      }
      this.previewItem = {
        shippingCompanyName: "ESL",
        shippingCompanyLogoUrl: "https://echola-pre.oss-cn-hangzhou.aliyuncs.com/file/carrier/ESL4010x.png",
        departureName: "南沙港",
        departureCountry: "中国",
        transferName: "",
        destinationName: "江门港",
        destinationCountry: "中国",
        startTime: "2023-11-02 15:49:42",
        endTime: "2023-11-15 15:49:42"
      }
    },
    seeRecords() {
      this.$refs.records.open(this.row.serviceId);
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if(!valid) return;
        let startTime = this.form.time.length ? this.$dayjs(this.form.time[0]).startOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
        let endTime = this.form.time.length ? this.$dayjs(this.form.time[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
        let obj = JSON.parse(JSON.stringify(this.form));
        delete obj.time;
        this.btnLoading = true;
        if(this.row) {
          editForwarderServer({
            ...obj,
            startTime,
            endTime
          }).then(() => {
            this.$message.success(this.$t('home.editSuccess'));
            this.close();
            this.$emit("updateList", false);
          }).finally(() => this.btnLoading = false);
          return;
        }
        createForwarderServer({
          ...obj,
          startTime,
          endTime
        }).then(() => {
          this.$message.success(this.$t('home.createSuccess'));
          this.close();
          this.$emit("updateList", true);
        }).finally(() => this.btnLoading = false);
      })
    }
  }
}
</script>

<style lang="less" scoped>
.drawerForm {
  padding: 0;
}
/deep/ .componentBox {
  .right {
    background: transparent;
    padding: 0;
    .components {
      .drawerForm {
        padding: 0;
        .serverItemBox {
          zoom: .7;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  .left {
    /deep/ .el-button {
      padding: 9px 0;
    }
  }
}
</style>