<template>
  <div class="componentBox">
    <div class="left">
      <div class="title">
        <div class="iconBox">
          <i class="el-icon-set-up"></i>
        </div>
        {{ $t('Configuration') }}
      </div>
      <div class="components">
        <slot name="config" />
      </div>
    </div>
    <div class="right">
      <div class="title">{{ $t('Preview') }}</div>
      <div class="components">
        <div class="drawerForm">
          <slot name="component" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.componentBox {
  display: flex;
  gap: 24px;
  margin-top: 32px;
  padding: 0 8px;
  .left {
    flex: 1;
    height: 400px;
    overflow: overlay;
    padding-bottom: 20px;
    .title {
      display: flex;
      align-items: center;
      gap: 6px;
      color: #637381;
      font-size: 14px;
      line-height: 28px;
      font-weight: 500;
      .iconBox {
        width: 20px;
        height: 20px;
        background: #122545;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        i {
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
    .components {
      margin-top: 24px;
    }
  }
  .right {
    flex: 1;
    background: #f3f3f3;
    border-radius: 4px;
    padding: 8px 12px;
    height: 350px;
    .title {
      font-size: 14px;
      color: #122545;
      font-weight: 500;
      line-height: 28px;
    }
    .components {
      width: 100%;
      height: 100%;
      padding-top: 50px;
    }
    .drawerForm {
      padding: 0 8px;
    }
  }
}
</style>