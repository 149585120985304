<template>
  <el-drawer
    :visible.sync="show"
    size="1176px"
    :before-close="handleClose"
    :show-close="true"
    :append-to-body="true"
    v-move-outside
    v-move-draw="980"
  >
    <div class="drawerHeader" slot="title">
      <div class="translateButton" v-if="show">
        <el-tabs
          class="special_tabs"
          v-model="pageType"
          size="small">
          <el-tab-pane :label="serverShopFlag == 1 ? $t(`noticeServer.Server`) : $t(`noticeServer.Shop`)" :name="'0'" />
          <el-tab-pane :label="$t('noticeServer.Case')" :name="'1'" />
          <el-tab-pane :label="$t('noticeServer.File')" :name="'2'" />
        </el-tabs>
      </div>
    </div>
    <div class="shopServerBox">
      <div class="searchBox">
        <div class="left">
          <el-input 
            v-model="params.keyword" 
            suffix-icon="el-icon-search" 
            size="small"
            style="width: 168px;"
            :placeholder="$t('Files.Search')" 
            @keyup.native.enter="updateList(true)"
          />
          <el-select
            v-model="params.sortType"
            filterable
            size="small"
            :placeholder="$t('order.Please select')"
            style="width: 150px;"
            @change="updateList(true)"
          >
            <el-option
              v-for="(item, index) in sortOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="right">
          <el-button 
            type="primary" 
            icon="el-icon-plus"
            size="small"
            @click="addList"
            v-if="companyId == params.companyId"
          >{{ $t('home.CIAdd') }}</el-button>
          <toolTipIcon 
            icon="iconfont icon-fenxiang" 
            :tooltipText="$t('order.share')"
            v-if="pageType != 2"
            @click="goShare"
          />
        </div>
      </div>
      <Server ref="Server" v-if="pageType == '0' && serverShopFlag == 1" :params="params" />
      <Shop ref="Shop" v-else-if="pageType == '0'" :params="params" />
      <Case ref="Case" v-if="pageType == '1'" :params="params"/>
      <File ref="File" v-if="pageType == '2'" :params="params" />
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import Server from './components/server.vue';
import Shop from './components/shop.vue';
import Case from './components/case.vue';
import File from './components/fileList.vue';

export default {
  name: "shopServerManage",
  components: {
    Server,
    Shop,
    Case,
    File
  },
  data() {
    return {
      show: false,
      pageType: "0",
      sortOptions: [{
        label: this.$t('noticeServer.From new to old'),
        value: 'desc'
      }, {
        label: this.$t('noticeServer.From old to new'),
        value: 'asc'
      }],
      params: {
        keyword: "",
        sortType: "desc",
        companyId: ""
      },
      serverShopFlag: ""
    }
  },
  computed: {
    ...mapGetters(["companyCurrentType", "companyId"])
  },
  watch: {
    pageType() {
      this.updateList(true);
    }
  },
  methods: {
    open(companyId, serverShopFlag) {
      this.show = true;
      this.params.companyId = companyId;
      this.serverShopFlag = serverShopFlag;
      this.$nextTick(() => {
        this.updateList(true);
      });
    },
    handleClose() {
      this.show = false;
    },
    goShare() {
      if(this.pageType == '0') {
        if(this.serverShopFlag == 1) {
          this.$refs.Server.offSiteShareEvent()
        } else {
          this.$refs.Shop.offSiteShareEvent()
        }
      }
      if(this.pageType == '1') {
        this.$refs.Case.offSiteShareEvent()
      }
      if(this.pageType == '2') {
        this.$refs.File.offSiteShareEvent()
      }
    },
    addList() {
      if(this.pageType == '0') {
        if(this.serverShopFlag == 1) {
          this.$refs.Server.addItem()
        } else {
          this.$refs.Shop.addItem()
        }
      }
      if(this.pageType == '1') {
        this.$refs.Case.addItem()
      }
      if(this.pageType == '2') {
        this.$refs.File.addItem()
      }
    },
    updateList(clear = false) {
      this.$nextTick(() => {
        if(this.pageType == '0') {
          if(this.serverShopFlag == 1) {
            this.$refs.Server.getList(clear)
          } else {
            this.$refs.Shop.getList(clear)
          }
        }
        if(this.pageType == '1') {
          this.$refs.Case.getList(clear)
        }
        if(this.pageType == '2') {
          this.$refs.File.getList(clear)
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.translateButton {
  display: inline-block;
  vertical-align: bottom;
}
.shopServerBox {
  padding: 20px;
}
.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  .left {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .right {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
</style>