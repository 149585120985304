<template>
  <el-drawer
    :title="$t('customerInfo.MessageBoards')"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="1060px"
    append-to-body
    v-move-outside
  >
    <div class="demo-drawer__content" v-loading="loading">
      <div class="containerLeft">
        <div class="scheduleTitle" v-if="mode == 'own' && manageCompany">
          <el-button icon="el-icon-plus" size="small" @click="addMessage">{{
            $t("messageBoard.NewMessage")
          }}</el-button>
        </div>
        <div
          class="noticeList"
          v-infinite-scroll="load"
          :infinite-scroll-distance="20"
        >
          <template v-if="messageList.length">
            <div 
              class="item" 
              :class="noticeActive == item.noticeId ? 'active' : ''"
              v-for="item in messageList" 
              :key="item.noticeId"
              @click="jump(item)">
              <member
                :info="item.createPerson"
                :size="48"
                :placement="'left'"
              />
              <div class="content">
                <div class="name" v-showTootip>{{ item.title }}</div>
                <div class="totalMember">{{ item.createTime }}</div>
              </div>
            </div>
          </template>
          <el-empty 
            v-else 
            :description="$t('overallSituation.noData')" 
            :image="$emptyImage"
            :image-size="90"
          />
        </div>
      </div>
      <div class="containerRight">
        <template  v-if="noticeFormItem">
          <div class="parentBox">
            <div class="name">
              <span v-if="!isEdit">{{ noticeFormItem.title }}</span>
              <el-input 
                v-model="noticeFormItem.title" 
                v-else
                size="mini"
                :placeholder="$t('home.Please enter a group name')" 
              />
              <i 
                class="iconfont icon-Vector"  
                @click="editFun"
                v-if="noticeFormItem.noticeId == 'weimingming'"
              ></i>
            </div>
            <i 
              class="iconfont icon-shanchu" 
              @click="deleteGroupFun"
              v-if="manageCompany && mode == 'own'"
            ></i>
          </div>
          <div class="time">{{ noticeFormItem.createTime }}</div>
          <div class="quillEditor">
            <quill-editor
              v-model="noticeFormItem.content"
              :options="editorOption"
              style="height: 100%;"
              :disabled="noticeFormItem.noticeId != 'weimingming'"
            />
          </div>
          <div class="btns" v-if="noticeFormItem.noticeId == 'weimingming'">
            <el-button
              size="small"
              type="primary"
              @click="createNotice"
            >{{ $t('home.Confirm') }}</el-button>
          </div>
        </template>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { getSendMessageList, createMessage, deleteMessage } from "@/api/message";
export default {
  name: "noticeDrawer",
  data() {
    return {
      mode: "own",
      drawer: false,
      direction: 'rtl',
      loading: false,
      noticeActive: null,
      isEdit: false,
      messageList: [],
      totalPage: 0,
      noticeFormItem: null,
      params: {
        companyId: '',
        currentPage: 1,
        pageSize: 20
      },
      editorOption: {
        placeholder: this.$t("messageBoard.writeAway"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [
              {
                header: 1,
              },
              {
                header: 2,
              },
            ], // 标题，键值对的形式；1、2表示字体大小
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
            ], //列表
            [
              {
                script: "sub",
              },
              {
                script: "super",
              },
            ], // 上下标
            [
              {
                indent: "-1",
              },
              {
                indent: "+1",
              },
            ], // 缩进
            [
              {
                direction: "rtl",
              },
            ], // 文本方向
            [
              {
                size: ["small", false, "large", "huge"],
              },
            ], // 字体大小
            [
              {
                header: [1, 2, 3, 4, 5, 6, false],
              },
            ], //几级标题
            [
              {
                color: [],
              },
              {
                background: [],
              },
            ], // 字体颜色，字体背景颜色
            [
              {
                font: [],
              },
            ], //字体
            [
              {
                align: [],
              },
            ], //对齐方式
            ["clean"], //清除字体样式
            ["image", "video"], //上传图片、上传视频
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'manageCompany'])
  },
  watch: {
    noticeActive() {

    }
  },
  methods: {
    open(isMode = "own", id = "", noticeId = "") {
      this.params.companyId = id;
      this.drawer = true;
      this.mode = isMode;
      this.noticeActive = noticeId;
      this.getList(true);
    },
    getList(clear = false) {
      this.loading = true;
      getSendMessageList(this.params).then((data) => {
        if(clear) this.messageList = [];
        this.messageList = this.messageList.concat(data.list);
        this.totalPage = data.pages;
        if(this.messageList[0]) {
          if(this.noticeActive) {
            this.noticeFormItem = this.messageList.filter(item => item.noticeId == this.noticeActive)[0];
          } else {
            this.noticeActive = this.messageList[0].noticeId;
            this.noticeFormItem = this.messageList[0];
          }
        } else {
          this.noticeActive = null;
          this.noticeFormItem = null;
        }
      }).finally(() => this.loading = false);
    },
    load() {
      if(this.totalPage < this.params.currentPage) return;
      this.params.currentPage++;
      this.getList();
    },
    addMessage() {
      if(this.messageList.filter((item) => item.noticeId == 'weimingming').length) return;
      this.messageList.unshift({
        noticeId: "weimingming",
        companyId: "", //公司主键
        title: "未命名", //标题
        content: "", //内容
        createPerson: this.userInfo, //创建人
        createTime: dayjs().format("YYYY-MM-DD HH:mm:ss") //创建时间
      });
      this.noticeActive = "weimingming";
      this.noticeFormItem = {
        noticeId: "weimingming",
        companyId: "", //公司主键
        title: "未命名", //标题
        content: "", //内容
        createPerson: this.userInfo, //创建人
        createTime: dayjs().format("YYYY-MM-DD HH:mm:ss") //创建时间
      }
      this.isEdit = true;
    },
    jump(row) {
      this.noticeActive = row.noticeId;
      this.noticeFormItem = row;
    },
    editFun() {
      this.isEdit = !this.isEdit;
    },
    deleteGroupFun() {
      if(this.noticeFormItem.noticeId === 'weimingming') {
        this.messageList = this.messageList.filter(item => item.noticeId != 'weimingming');
        this.isEdit = false;
        if(this.messageList[0]) {
          this.noticeActive = this.messageList[0].noticeId;
          this.noticeFormItem = this.messageList[0];
        } else {
          this.noticeActive = null;
          this.noticeFormItem = null;
        }
        return;
      }
      this.$confirm(this.$t('messageBoard.This Message will be deleted?'), this.$t('messageBoard.Notification'), {
        confirmButtonText: this.$t("messageBoard.Confirm"),
        cancelButtonText: this.$t("messageBoard.Cancel"),
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          deleteMessage({ unid: this.noticeFormItem.noticeId }).then(() => {
            this.$message.success(this.$t('todo.Delete Successful'));
            this.getList(true);
          }).catch((error) => this.loading = false);
        }).catch(() => {});
    },
    createNotice() {
      var data_message = {
        content: this.noticeFormItem.content,
        title: this.noticeFormItem.title,
      };
      if(!this.noticeFormItem.content) {
        this.$message.warning(this.$t('home.Please enter a notice content'))
        return;
      }
      this.loading = true;
      createMessage(data_message).then(() => {
        this.getList(true);
        this.isEdit = false;
        this.$message.success(this.$t('home.createSuccess'));
      }).finally(() => this.loading = false);
    },
    handleClose() {
      this.drawer = false;
    }
  },
}
</script>

<style lang="less" scoped>
.noticeList {
  width: 100%;
  overflow: overlay;
  height: calc(100% - 62px);
  .item {
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    .content {
      flex: 1;
      min-width: 0;
      .name {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #122545;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .totalMember {
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
      }
    }
    &.active {
      background: #f7f9fc;
      border-right: 2px solid #076f49;
    }
    &:hover {
      background: #f7f9fc;
    }
  }
}
.time {
  font-size: 12px;
  color: #757d8a;
  line-height: 16px;
}
.quillEditor {
  height: calc(100% - 80px);
  margin-top: 20px;
  /deep/ .ql-editor {
    padding: 0;
    font-size: 14px;
    color: #757d8a;
    &::before {
      left: 0;
    }
  }
}
</style>
