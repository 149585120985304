<template>
  <div>
    <el-dialog
      :title="!row ? $t('home.CIAdd') : $t('home.Edit')"
      :visible.sync="show"
      width="668px"
      append-to-body
      :before-close="close"
      v-move-outside
    >
      <layout>
        <template #config>
          <el-form
            ref="ruleForm"
            v-if="show"
            label-width="0"
            size="small"
            :model="form"
            :rules="rules"
            class="drawerForm"
          >
            <el-form-item>
              <div class="title">{{ $t("image") }}{{ `(${form.picList.length}/5)` }}</div>
              <div class="avatarForm">
                <div class="productImageList">
                  <div 
                    class="item" 
                    v-for="(item, index) in form.picList" 
                    :key="index">
                    <img :src="item.fileUrl" />
                    <div class="mask">
                      <i @click="deleteImage(index)" class="iconfont icon-shanchu"></i>
                    </div>
                  </div>
                  <div class="uploadBox" v-if="form.picList.length < 5">
                    <i class="el-icon-plus"></i>
                    <input type="file" @change="confirmUploadImage">
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="goodsName">
              <div class="title">{{ $t("Shop name") }}</div>
              <div class="avatarForm">
                <el-input
                  type="text"
                  :placeholder="$t('order.Please input content')"
                  v-model="form.goodsName"
                  maxlength="50"
                  show-word-limit
                  @input="generateChange"
                />
              </div>
            </el-form-item>
            <el-form-item prop="description">
              <div class="title">{{ $t("Description") }}</div>
              <div class="avatarForm">
                <el-input
                  type="textarea"
                  :placeholder="$t('order.Please input content')"
                  v-model="form.description"
                  maxlength="50"
                  show-word-limit
                  @input="generateChange"
                />
              </div>
            </el-form-item>
            <el-form-item>
              <div class="title">{{ $t("Price (for reference only)") }}</div>
              <div class="avatarForm">
                <el-input
                  type="text"
                  :placeholder="$t('order.Please input content')"
                  v-model="form.price"
                  maxlength="14"
                  show-word-limit
                  @input="() => {
                    form.price = form.price.replace(/\D*(\d{7})(\.?)(\d{0,3})\d*/,'$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,3})/g)[0] || ''
                    generateChange()
                  }"
                />
              </div>
            </el-form-item>
            <el-form-item>
              <div class="title">{{ $t("Attachment") }}</div>
              <el-upload
                v-loading="uploadLoding"
                :auto-upload="false"
                action="#"
                drag
                :show-file-list="false"
                :on-change="handleChange"
                :class="!form.attachmentId.url ? 'ec-upload' : 'ec-uploader'"
                :accept="fileTypeFileConfig.supportFileTypes"
              >
                <template v-if="!form.attachmentId.url">
                  <i class="iconfont icon-shangchuan"></i>
                  <div class="el-upload__text">
                    {{ $t("event.Drag & Drop files here OR Browse Files") }}
                  </div>
                </template>
                <template v-else>
                  <div class="ec-upload__left">
                    <i
                      class="iconfont_file"
                      :class="$isFileTypeIcon(fileName.split('.')[1])"
                    ></i>
                    <div class="name">
                      <p>{{ fileName }}</p>
                    </div>
                  </div>
                  <i class="iconfont icon-shanchu" @click.stop="handleRemove"></i>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>
        </template>
        <template #component>
          <shopItem :item="previewItem" />
          <fileBlocks
            v-if="form.attachmentId.url"
            type="message"
            background="#F7F9FC"
            :embedUrl="form.attachmentId.url"
            :embedId="form.attachmentId.id"
          />
        </template>
      </layout>
      <span slot="footer" class="dialog-footer">
        <div class="left">
          <el-button 
            v-if="row"
            class="cancel_button" 
            size="small" 
            icon="el-icon-time"
            @click="seeRecords">
            {{ $t('Operation records') }}
          </el-button>
        </div>
        <div class="right">
          <el-button 
            class="cancel_button" 
            size="small" 
            @click="close">
            {{ $t('home.Cancel') }}
          </el-button>
          <el-button 
            type="primary" 
            size="small" 
            :loading="btnLoading"
            @click="confirm">
            {{ $t('home.Confirm') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
    <records ref="records" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import layout from '../../customFormConfig/config/components/layout.vue';
import shopItem from '../itemComponents/shop.vue';
import records from "./records.vue";

import { createShop, editShop } from "@/api/shopServer.js";
import { uploadFileUrl } from "@/api/files.js";

export default {
  components: {
    layout,
    shopItem,
    records
  },
  props: {
    companyId: {
      type: String | Number,
      default: ""
    }
  },
  data() {
    return {
      show: false,
      row: null,
      btnLoading: false,
      uploadLoding: false,
      form: {
        picList: [],
        goodsName: "",
        description: "",
        price: "",
        attachmentId: {
          id: "",
          url: ""
        }
      },
      previewItem: {
        picList: [],
        goodsName: this.$t('event.Title'),
        description: this.$t('Description'),
        price: "0.00"
      },
      rules: {
        goodsName: [{
          required: true, message: this.$tc('Please input', this.$t("Shop name")), trigger: 'blur'
        }],
        description: [{
          required: true, message: this.$tc('Please input', this.$t("Description")), trigger: 'blur'
        }]
      },
      fileTypeImageConfig: null,
      fileTypeFileConfig: null
    }
  },
  computed: {
    ...mapGetters(['fileType']),
    fileName() {
      let text = this.form.attachmentId.url.split("/")?.pop();
      let index_ = text.lastIndexOf("_");
      let indexdian = text.lastIndexOf(".");
      return text.substring(0, index_) + text.substring(indexdian, text.length);
    }
  },
  mounted() {
    this.fileTypeImageConfig = this.fileType.find(item => item.configCode == 'goods_images');
    this.fileTypeFileConfig = this.fileType.find(item => item.configCode == 'goods_file');
  },
  methods: {
    open(row = null) {
      this.row = row;
      this.show = true;
      if(row) {
        this.form = {
          companyId: this.companyId,
          goodsId: row.goodsId,
          picList: row.picList,
          goodsName: row.goodsName,
          description: row.description,
          price: row.price,
          attachmentId: {
            id: row.attachmentId,
            url: row.attachmentUrl
          }
        }
        setTimeout(() => {
          this.generateChange();
        }, 300);
      }
    },
    generateChange() {
      this.previewItem = {
        picList: this.form.picList,
        goodsName: this.form.goodsName || this.$t('event.Title'),
        description: this.form.description || this.$t('Description'),
        attachment: this.form.attachmentId.url,
        price: this.form.price || "0.00"
      }
    },
    seeRecords() {
      this.$refs.records.open(this.row.goodsId);
    },
    deleteImage(index) {
      this.form.picList.splice(index, 1);
      this.generateChange();
    },
    beforeUpload(file, typeConfig) {
      let type = '.'+file.name.split(".").pop();
      if(!typeConfig.supportFileTypes.split(",").includes(type.toLowerCase())) {
        this.$message.error(this.$t("File format not supported"));
        return;
      }
      const isLt10M = file.size / 1024 / 1024 < Number(typeConfig.maxUploadSize);
      if (!isLt10M) {
        this.$message.error(this.$tc("order.File is too large to upload", Number(typeConfig.maxUploadSize)));
      }
      return isLt10M;
    },
    confirmUploadImage(event) {
      let files = event.target.files[0]
      if (!this.beforeUpload(files, this.fileTypeImageConfig)) return;
      var data_up = new FormData();
      data_up.append("files", files);
      data_up.append("configCode", "goods_images");
      uploadFileUrl(data_up).then((data) => {
        if (!data) return;
        this.form.picList.push({
          fileId: data[0].fileId,
          fileUrl: data[0].savePath,
        });
        this.generateChange();
      })
      event.target.value = "";
    },
    handleRemove() {
      this.form.attachmentId.id = "";
      this.form.attachmentId.url = "";
    },
    handleChange(file) {
      //文件数量改变
      if (!this.beforeUpload(file, this.fileTypeFileConfig)) return;
      this.confirmUpload(file);
    },
    confirmUpload(file) {
      this.uploadLoding = true;
      var data_up = new FormData();
      data_up.append("files", file.raw);
      data_up.append("configCode", "goods_file");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        this.form.attachmentId.url = response[0].savePath;
        this.form.attachmentId.id = response[0].fileId;
      }).finally((error) => {
        this.uploadLoding = false;
      });
    },
    close() {
      this.show = false;
      this.row = null;
      this.form = {
        picList: [],
        goodsName: "",
        description: "",
        price: "",
        attachmentId: {
          id: "",
          url: ""
        }
      }
      this.previewItem = {
        picList: [],
        goodsName: this.$t('event.Title'),
        description: this.$t('Description'),
        price: "0.00"
      }
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if(!valid) return;
        let obj = JSON.parse(JSON.stringify(this.form));
        delete obj.picList;
        this.btnLoading = true;
        if(this.row) {
          editShop({
            ...obj,
            goodsPicId: this.form.picList.map(item => item.fileId).join(","),
            attachmentId: obj.attachmentId.id,
            companyId: this.companyId
          }).then(() => {
            this.$message.success(this.$t('home.editSuccess'));
            this.close();
            this.$emit("updateList", false);
          }).finally(() => {
            this.btnLoading = false;
          });
          return;
        }
        createShop({
          ...obj,
          goodsPicId: this.form.picList.map(item => item.fileId).join(","),
          attachmentId: obj.attachmentId.id,
          companyId: this.companyId
        }).then(() => {
          this.$message.success(this.$t('home.createSuccess'));
          this.close();
          this.$emit("updateList", true);
        }).finally(() => {
          this.btnLoading = false;
        });
      })
    }
  }
}
</script>

<style lang="less" scoped>
.drawerForm {
  padding: 0;
}
/deep/ .componentBox {
  .right {
    background: transparent;
    padding: 0;
    min-width: 0;
    .components {
      padding-top: 10px;
      .drawerForm {
        padding: 0;
        height: 100%;
        .caseItemBox {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.productImageList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .item {
    width: 80px;
    height: 60px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 4px;
    }
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .5);
      opacity: 0;
      transition: all .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
      }
    }
    &:hover {
      .mask {
        opacity: 1;
      }
    }
  }
  .uploadBox {
    width: 80px;
    height: 60px;
    border-radius: 4px;
    position: relative;
    border: 1px dashed #DCE2EC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}
.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .item {
    border-radius: 4px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #ffffff;
    line-height: 22px;
    i {
      cursor: pointer;
    }
  }
  .addTag {
    height: 32px;
    width: 88px;
    border: 1px dashed #0E559C;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      color: #0E559C;
      font-weight: 500;
    }
  }
}
.colorBox {
  display: flex;
  align-items: center;
  margin-top: 18px;
  .label {
    color: #637381;
    font-size: 12px;
    line-height: 22px;
    flex: 1;
  }
  .colors {
    display: flex;
    align-items: center;
    gap: 13px;
    .item {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        position: relative;
        &::before {
          content: "\e6da";
          font-family: "element-icons";
          font-size: 16px;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          width: 14px;
          height: 14px;
          background: #48AC42;
          color: #ffffff;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  .left {
    /deep/ .el-button {
      padding: 9px 0;
    }
  }
}
</style>